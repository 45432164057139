import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../FormInputs/TextForm";
import SelectForm from "../../../../FormInputs/SelectForm";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";
import { propExistsInArray } from "../../../../../utils/ArrayUtils";
import BaseModelSection from "./BaseModelSection";

const ModelSection: React.FC<ModelSectionProps> = ({
	setMCManagementFieldByKeyOnChange,
	fieldsAvailableForEdition,
	paqueteOptions,
	fields,
}) => {
	const { t } = useTranslation("order");
	return (
		<>
			<BaseModelSection fields={fields} />
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialTrimmingPackages")}
					value={fields.commercialTrimmingPackages}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.model.paquete")}
					labelId={"sections-model-paquete"}
					value={fields.paqueteId}
					options={paqueteOptions}
					editable={propExistsInArray("paqueteId", fieldsAvailableForEdition)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"paqueteId",
							e.target.value as string
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
