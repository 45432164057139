import { configure } from "mobx";
import BasePreFilterStringStore from "../../../../PreFilter/Stores/BasePreFilterStringStore";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCustomerStoreSAS extends BasePreFilterStringStore {
	filterKey = "finalCustomer";
	filterSize = 100;
	operator = "contains";

	getSpecificFilter(): any {
		return getFilterObjectArrayOfStringKeyBySize(
			this.filterSize,
			this.preFilter,
			this.filterKey,
			this.operator
		);
	}
}

export default PreFilterCustomerStoreSAS;
