import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import {
	FollowUpArgAccountingEditableProps,
	FollowUpArgArgMarketCompanyEditableProps,
	FollowUpArgBusinessEditableProps,
	FollowUpArgEngineeringEditableProps,
	FollowUpArgFinancialEditableProps,
	FollowUpArgBaseEditableProps,
	FollowUpArgLogisticEditableProps,
} from "../../pages/FollowUpArg/Details/interfaces/FollowUpArgEditable";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { VehicleType } from "../../utils/GenericTypes";
import {
	BatchDataProps,
	BatchBaseDataProps,
	BatchBusinessUserDataProps,
	BatchEngineeringUserDataProps,
	BatchFinancialUserDataProps,
	BatchLogisticUserDataProps,
} from "../../pages/FollowUpArg/List/interfaces/BatchEdition/BatchDataProps";
import { VehicleTypeEnum } from "../../utils/enums/VehicleTypeEnum";

const base = "/followUpArg";

export class FollowUpArgService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list?vehicleType=${VehicleTypeEnum.Truck}&${toDataSourceRequestString(dataState)}`
		);

	listBusPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list?vehicleType=${VehicleTypeEnum.Bus}&${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/details/${id}`);

	getDetailsBus = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/detailsBus/${id}`);

	getDetailsVariants = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${id}`);

	getDetailsVariantsBus = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variantsBus/${id}`);

	getDetailsMCDelivery = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/mcDelivery/${id}`);

	getDetailsMCManagement = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/mcManagement/${id}`);

	getShippingCompanies = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/shippingCompanies`);

	update = async (
		orderDetails: FollowUpArgArgMarketCompanyEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/update`, JSON.stringify(orderDetails));

	updateGeneralComment = async (
		orderDetails: FollowUpArgBaseEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateGeneralComment`,
			JSON.stringify(orderDetails)
		);

	updateAsFinancial = async (
		orderDetails: FollowUpArgFinancialEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsFinancial`,
			JSON.stringify(orderDetails)
		);

	updateAsLogistic = async (
		orderDetails: FollowUpArgLogisticEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsLogistic`,
			JSON.stringify(orderDetails)
		);

	updateAsAccounting = async (
		orderDetails: FollowUpArgAccountingEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsAccounting`,
			JSON.stringify(orderDetails)
		);

	updateAsBusiness = async (
		orderDetails: FollowUpArgBusinessEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsBusiness`,
			JSON.stringify(orderDetails)
		);

	updateAsEngineering = async (
		orderDetails: FollowUpArgEngineeringEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateAsEngineering`,
			JSON.stringify(orderDetails)
		);

	csvExport = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	csvExportBus = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csvBus?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	updateBatch = async (orderDetails: BatchDataProps): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/batch`, JSON.stringify(orderDetails));

	updateBatchFinancialUser = async (
		orderDetails: BatchFinancialUserDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchFinancialUser`,
			JSON.stringify(orderDetails)
		);

	updateBatchEngineeringUser = async (
		orderDetails: BatchEngineeringUserDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchEngineeringUser`,
			JSON.stringify(orderDetails)
		);

	updateBatchLogisticUser = async (
		orderDetails: BatchLogisticUserDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchLogisticUser`,
			JSON.stringify(orderDetails)
		);

	updateBatchBusinessUser = async (
		orderDetails: BatchBusinessUserDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchBusinessUser`,
			JSON.stringify(orderDetails)
		);

	updateBatchGeneralComment = async (
		orderDetails: BatchBaseDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batchGeneralComment`,
			JSON.stringify(orderDetails)
		);

	getSummaryTruck = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/summaryTruck`);

	getSummaryBus = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/summaryBus`);

	getProgressSummary = async (vehicleType: VehicleType): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/progressSummary?vehicleType=${vehicleType}`
		);
}
