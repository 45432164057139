import moment from "moment";
import { DateRangeFormProps } from "./GenericInterfaces";

export const getStringToDateFormat = (date: string) =>
	new Date(date.replace(/-/g, "/").replace(/T.+/, ""));

export const handleDateChange = (
	date: Date | null,
	format: string,
	set: (date: Date | undefined) => void,
	setIsValid?: (isValid: boolean) => void,
	setEnd?: (date: Date | undefined) => void
) => {
	const newDateMoment = moment(date);
	if (moment(date, format, true).isValid()) {
		set(newDateMoment.toDate());
		setIsValid && setIsValid(true);
	} else if (date == null) {
		set(undefined);
		setEnd && setEnd(undefined);
		setIsValid && setIsValid(false);
	}
};

export const handleDateRangeChange = (
	date: Date | null,
	format: string,
	set: (dateRange: DateRangeFormProps | undefined) => void,
	startDate: Date,
	endDate: Date
) => {
	const newDateMoment = moment(date);
	if (
		moment(date, format, true).isValid() &&
		newDateMoment.toDate() >= startDate &&
		newDateMoment.toDate() <= endDate
	) {
		set({
			startDate: newDateMoment.startOf("month").toDate(),
			endDate: newDateMoment.endOf("month").toDate(),
		});
	} else if (date == null) {
		set(undefined);
	}
};

export function getStartDate(date: Date | null): Date | null {
	return date ? moment(date).startOf("day").toDate() : null;
}
