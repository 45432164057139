import { TFunction } from "i18next";
import { getValueGrid, getSortValueGrid } from "./PaymentTermsUtil";
import { IColumnProps } from "../components/kendo/DataGridApi/interfaces/IColumnProps";
import { getFollowUpArgProbabilityOptions } from "./enums/ArgDeliveryProbabilityEnum";
import {
	FOLLOWUP_MENU_ARG_BUS,
	FOLLOWUP_MENU_ARG_TRUCK,
} from "../constants/Menus/FollowUpMenuUtils";
import { ISortMapProps } from "../components/kendo/DataGridApi/interfaces/ISortMapProps";

const getPaqueteColumn = (t: TFunction<"order"[], undefined>, menu: string) => {
	if (menu === FOLLOWUP_MENU_ARG_BUS || menu === FOLLOWUP_MENU_ARG_TRUCK) {
		return [
			{
				field: "managementDetail.paquete",
				sortField: "orderMCManagementDetail.paquete.paqueteDescription",
				title: t("gridFields.paquete"),
				width: 130,
				sortable: false,
			},
		];
	}

	return [];
};

function getBooleanColumn(
	field: string,
	t: TFunction<"order"[], undefined>
): IColumnProps {
	return {
		field: field,
		sortField: "orderMCDelivery." + field,
		title: t("gridFields." + field),
		width: 100,
		alwaysVisible: true,
		headerAlign: "center",
		canWrap: true,
		customCell: {
			type: "booleanCell",
			value: field,
		},
	};
}

function getCommonColumns(
	t: TFunction<"order"[], undefined>,
	language: string,
	menu: string
): Array<IColumnProps> {
	return [
		{
			field: "managementDetail.billingForecast",
			sortField: "orderMCManagementDetail.billingForecast",
			title: t("gridFields.billingForecast"),
			width: 110,
			headerAlign: "center",
			canWrap: true,
			customCell: {
				type: "dateTimeCell",
				date: "managementDetail.billingForecast",
				format: "MMMM/yy",
			},
		},
		getBooleanColumn("firmBilling", t),
		getBooleanColumn("financialDelivery", t),
		getBooleanColumn("physicalDelivery", t),
		getBooleanColumn("finalDelivery", t),
		{
			field: "order.assemblyOrder",
			sortField: "assemblyOrder",
			title: t("gridFields.ao"),
			width: 135,
			alwaysVisible: true,
			customCell: {
				type: "linkCell",
				to: "./",
				toParams: "order.id",
				value: "order.assemblyOrder",
			},
		},
		{
			field: "order.chassi",
			sortField: "chassi",
			title: t("gridFields.chassi"),
			alwaysVisible: true,
			width: 80,
		},
		{
			field: "order.greenOk",
			sortField: "greenOk",
			title: t("gridFields.greenOk"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.greenOk",
				format: "DD/MM/yy",
			},
		},
		{
			field: "order.businessControlVehicle",
			sortField: "businessControlVehicle",
			title: t("gridFields.bc_vehicle"),
			width: 110,
		},
		...getPaqueteColumn(t, menu),
		{
			field: "order.asWeekDate",
			sortField: "asWeekDate",
			title: t("gridFields.asWeek"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "order.asWeekDate",
				format: "DD/MM/yy",
			},
		},
		{
			field: "managementDetail.dealerName",
			sortField: "orderMCManagementDetail.dealer.name",
			title: t("gridFields.dealerName"),
		},
		{
			field: "order.finalCustomer",
			sortField: "finalCustomer",
			title: t("gridFields.customerSAS"),
		},
		{
			field: "managementDetail.destination",
			sortField: "orderMCManagementDetail.destination",
			title: t("gridFields.destination"),
		},
		{
			field: "managementDetail.currency",
			sortField: "orderMCManagementDetail.currency",
			title: t("gridFields.currency"),
			width: 80,
		},
		{
			field: "managementDetail.finalValue",
			sortField: "orderMCManagementDetail.finalValue",
			title: t("gridFields.finalValue"),
			width: 70,
			headerAlign: "center",
			customCell: {
				type: "numberCell",
				value: "managementDetail.finalValue",
				fractionDigits: 2,
			},
		},
		{
			field: getValueGrid(language),
			sortField: getSortValueGrid(language),
			title: t("gridFields.paymentForm"),
			width: 170,
		},
		{
			field: "managementDetail.shippingDate",
			sortField: "orderMCManagementDetail.shippingDate",
			title: t("gridFields.shippingDate"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "managementDetail.shippingDate",
				format: "DD/MM/yy",
			},
		},
		{
			field: "managementDetail.certificateDate",
			sortField: "orderMCManagementDetail.certificateDate",
			title: t("gridFields.certificateDate"),
			width: 90,
			customCell: {
				type: "dateTimeCell",
				date: "managementDetail.certificateDate",
				format: "DD/MM/yy",
			},
		},
		{
			field: "localization",
			sortField: "orderMCManagementDetail.vehicleLocation.name",
			title: t("gridFields.localization"),
			width: 250,
		},
		{
			field: "managementDetail.salesSupportComments",
			sortField: "orderMCManagementDetail.salesSupportComments",
			title: t("gridFields.salesSupportComments"),
		},
		{
			field: "managementDetail.financialComments",
			sortField: "orderMCManagementDetail.financialComments",
			title: t("gridFields.financialComments"),
		},
		{
			field: "managementDetail.commercialComments",
			sortField: "orderMCManagementDetail.commercialComments",
			title: t("gridFields.commercialComments"),
		},
		{
			field: "managementDetail.accountingComments",
			sortField: "orderMCManagementDetail.accountingComments",
			title: t("gridFields.accountingComments"),
		},
		{
			field: "managementDetail.logisticComments",
			sortField: "orderMCManagementDetail.logisticComments",
			title: t("gridFields.logisticComments"),
		},
	];
}

export function getClosedPeriodGridColumns(
	t: TFunction<"order"[], undefined>,
	language: string,
	menu: string
): Array<IColumnProps> {
	return getCommonColumns(t, language, menu);
}

export function getFollowUpGridColumns(
	t: TFunction<"order"[], undefined>,
	language: string,
	menu: string
): Array<IColumnProps> {
	const commonColumns = getCommonColumns(t, language, menu);

	const additionalColumns = [
		{
			field: "order.id",
			sortField: "id",
			title: "",
			alwaysVisible: true,
			width: 40,
			locked: true,
			customCell: {
				type: "updateCell",
				key: "order.id",
			},
		},
		{
			field: "deliveryProbability",
			sortField: "orderMCDelivery.deliveryProbability",
			title: " ",
			alwaysVisible: true,
			width: 28,
			locked: true,
			customCell: {
				type: "optionCell",
				value: "deliveryProbability",
				options: getFollowUpArgProbabilityOptions(t),
				badge: "circle",
			},
		},
	];

	return [...additionalColumns, ...commonColumns];
}

export function getClosedPeriodSortFieldsMap(
	t: TFunction<"order"[], undefined>,
	language: string,
	menu: string
): Array<ISortMapProps> {
	const columns = getClosedPeriodGridColumns(t, language, menu);
	return columns.map((m) => ({ field: m.field, sortField: m.sortField }));
}

export function getFollowUpSortFieldsMap(
	t: TFunction<"order"[], undefined>,
	language: string,
	menu: string
): Array<ISortMapProps> {
	const columns = getFollowUpGridColumns(t, language, menu);
	return columns.map((m) => ({ field: m.field, sortField: m.sortField }));
}
