import * as AuthGroups from "./authGroups";

export const groupsAllowedToSeeFollowUpArgentinaMenu: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, ...AuthGroups.ARG_ALL_USERS];

export const ownerGroupsOfFollowUpArg: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.ARG_MARKET_COMPANY,
	...AuthGroups.ARG_DEALERS,
];

export const groupsToUpdateFollowUpArg: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.ARG_MARKET_COMPANY,
];

export const groupsToUpdateFollowUpArgGeneralComment: Array<AuthGroups.AuthGroupsType> =
	[...AuthGroups.ARG_DEALERS];

export const groupsToUpdateFollowUpArgFinancial: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ARG_MARKET_COMPANY_FINANCIAL];

export const groupsToUpdateFollowUpArgLogistic: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ARG_MARKET_COMPANY_LOGISTIC];

export const groupsToUpdateFollowUpArgAccounting: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ARG_MARKET_COMPANY_ACCOUNTING];

export const groupsToUpdateFollowUpArgBusiness: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ARG_MARKET_COMPANY_BUSINESS];

export const groupsToUpdateFollowUpArgEngineering: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ARG_MARKET_COMPANY_ENGINEERING];
