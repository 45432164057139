import React from "react";
import { observer } from "mobx-react";
import { CLOSED_PERIOD_MENU_ARG_TRUCK } from "../../../../constants/Menus/ClosedPeriodMenuUtils";
import { useStores } from "../stores/RootStore";
import BaseIndex from "./BaseIndex";

const TruckIndex: React.FC = () => {
	const { setMenu, menu, reset } = useStores().listStore;
	const fetch = React.useCallback(async () => {
		reset();
		setMenu(CLOSED_PERIOD_MENU_ARG_TRUCK);
	}, [reset, setMenu]);
	React.useEffect(() => {
		fetch();
	}, [fetch]);
	return <>{menu === CLOSED_PERIOD_MENU_ARG_TRUCK && <BaseIndex />}</>;
};

export default observer(TruckIndex);
