import { action, configure, makeObservable, toJS } from "mobx";
import BasePreFilterStringArrayStore from "../../../../PreFilter/Stores/BasePreFilterStringArrayStore";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import { getLabel } from "../../../../../utils/VariantsUtil";
import { IPreFilterCommonOption } from "../../../../PreFilter/Interfaces/IPreFilter";

configure({ enforceActions: "always" });

class PreFilterPaymentFormStore extends BasePreFilterStringArrayStore {
	constructor(
		rootStore: any,
		private readonly api: CtadmvenApi
	) {
		super(rootStore, "orderMCManagementDetail.paymentTermsId");
		makeObservable(this);
	}

	@action setPaymentFormOptions = (data: any, language: string) => {
		this.preFilterOptions = [];

		if (!data) {
			return;
		}

		this.preFilterOptions = toJS(data).map(
			(m: any) =>
				({
					label: getLabel(m, language),
					value: m.id,
				}) as IPreFilterCommonOption
		);
	};

	@action getOptions = async () =>
		this.baseGetOptions(
			this.setPaymentFormOptions,
			this.api.paymentTermsService.getPaymentTerms
		);
}
export default PreFilterPaymentFormStore;
