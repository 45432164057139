import { closedPeriodUpdateModel } from "../models/ClosedPeriodUpdateModel";

function getEditableInterfaceObjectKeys(obj: any) {
	return Object.keys(obj);
}

export function getFieldsAvailableForEdition(canUpdate: boolean) {
	if (canUpdate) {
		return getEditableInterfaceObjectKeys(closedPeriodUpdateModel);
	}
	return [];
}
