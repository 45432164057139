import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../FormInputs/TextForm";
import SelectForm from "../../../../FormInputs/SelectForm";
import DateForm from "../../../../FormInputs/DateForm";
import { ForecastBillingSectionProps } from "../../interfaces/Sections/ForecastBillingSection";
import { propExistsInArray } from "../../../../../utils/ArrayUtils";

const ForecastBillingSection: React.FC<ForecastBillingSectionProps> = ({
	fieldsAvailableForEdition,
	fields,
	setMCManagementFieldByKeyOnChange,
	shippingCompaniesOptions,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.forecastBilling.title")}
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.shippingDate")}
					value={fields.shippingDate}
					editable={propExistsInArray(
						"shippingDate",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("shippingDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.forecastBilling.shippingNumber")}
					value={fields.shippingNumber}
					editable={propExistsInArray(
						"shippingNumber",
						fieldsAvailableForEdition
					)}
					name="shippingNumber"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("shippingNumber", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.certificateDate")}
					value={fields.certificateDate}
					editable={propExistsInArray(
						"certificateDate",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("certificateDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.forecastBilling.billingForecast")}
					inputFormat="MMMM/yy"
					value={fields.billingForecast}
					editable={propExistsInArray(
						"billingForecast",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("billingForecast", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.forecastBilling.shippingCompany")}
					labelId={"sections.forecastBilling-shippingCompany-label"}
					value={fields.shippingCompany}
					options={shippingCompaniesOptions}
					editable={propExistsInArray(
						"shippingCompany",
						fieldsAvailableForEdition
					)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"shippingCompany",
							e.target.value as string
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.forecastBilling.destination")}
					value={fields.destination}
					editable={propExistsInArray("destination", fieldsAvailableForEdition)}
					name="destination"
					inputProps={{ maxLength: 150 }}
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("destination", e.target.value)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(ForecastBillingSection);
