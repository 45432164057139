import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import { IPreFilterCommonOption } from "../../../../PreFilter/Interfaces/IPreFilter";
import { getFilterObject } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCurrencyStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: number | "" = "";
	@observable preFilterOptions: Array<IPreFilterCommonOption> = [];

	constructor(rootStore: any) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.loading = false;
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () =>
		this.preFilter === ""
			? []
			: [
					getFilterObject(
						"orderMCManagementDetail.currency",
						this.preFilter,
						"eq"
					),
				];

	@action set = (currencyFilter: number) => (this.preFilter = currencyFilter);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const currencyFilters = this.get();

		if (currencyFilters) andFilters = andFilters.concat(currencyFilters);

		return andFilters;
	}

	@action setOptions = (options: Array<IPreFilterCommonOption>) =>
		(this.preFilterOptions = options);

	@action hasPreFilter = () => this.preFilter !== "";
}

export default PreFilterCurrencyStore;
