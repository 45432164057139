import { action, configure, makeObservable, observable } from "mobx";
import _ from "lodash";
import React from "react";
import { StoreUtils } from "../../../../utils/StoreUtils";
import { ClosedPeriodUpdateProps } from "../interfaces/ClosedPeriodUpdateProps";
import {
	closedPeriodUpdateModel,
	closedPeriodUpdateModelBuilder,
} from "../models/ClosedPeriodUpdateModel";
import { ArgDetailsBaseStore } from "../../../../components/Arg/DetailsBase/stores/ArgDetailsBaseStore";

configure({ enforceActions: "always" });

class ClosedPeriodArgStore extends ArgDetailsBaseStore {
	@observable initialEditableDetails: ClosedPeriodUpdateProps =
		closedPeriodUpdateModel;

	constructor() {
		super();
		makeObservable(this);
	}

	resetStore = () => {
		this.initialEditableDetails = closedPeriodUpdateModel;
		this.resetBaseStore();
	};

	getEditableFields = () => closedPeriodUpdateModelBuilder(this.fields);

	//========================================set observables=========================================
	setEditableDetails = () =>
		(this.initialEditableDetails = this.getEditableFields());

	setHasChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.getEditableFields()
		);
	};
	//END: observables

	//=========================================load details=========================================
	loadDetails = async (orderId: string) => {
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetails(orderId),
			this.setTruckFieldsDetails,
			orderId
		);
	};

	loadDetailsBus = async (orderId: string) => {
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsBus(orderId),
			this.setBusFieldsDetailsBus,
			orderId
		);
	};

	loadVariants = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsVariants(orderId),
			this.setVariants,
			orderId
		);
	};

	loadVariantsBus = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsVariantsBus(orderId),
			this.setVariants,
			orderId
		);
	};

	loadOrderMktCompanyDelivery = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsMCDelivery(orderId),
			this.setOrderMCDelivery,
			orderId
		);
	};

	loadOrderMktCompanyManagement = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.closedPeriodService.getDetailsMCManagement(orderId),
			this.setOrderMCManagement,
			orderId
		);
	};
	//END: load details

	@action openClosedPeriod = async () => {
		await this.api.closedPeriodService.openClosedPeriod(this.fields.id);
	};

	update = async () => {
		return await this.api.closedPeriodService.update(this.getEditableFields());
	};
}

const ClosedPeriodArgStoreContext = React.createContext(
	new ClosedPeriodArgStore()
);

export const useClosedPeriodArgStore = () =>
	React.useContext(ClosedPeriodArgStoreContext);
