import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Link, Tooltip } from "@mui/material";
import TextForm from "../../../../FormInputs/TextForm";
import DateForm from "../../../../FormInputs/DateForm";
import { GeneralSectionProps } from "../../interfaces/Sections/GeneralSection";

const GeneralSection: React.FC<GeneralSectionProps> = ({ fields }) => {
	const { t } = useTranslation("order");

	async function handleOpenInNewTab() {
		await navigator.clipboard.writeText(fields.assemblyOrder);

		let path = `https://secure4.volvo.com/om/SearchOrder/SearchOrder`;
		window.open(path, "_blank", "noopener,noreferrer");
	}

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				<Tooltip
					title={t("tooltips.accessOrderManager").toString()}
					aria-label={t("tooltips.accessOrderManager")}
				>
					<Link
						className="link"
						component="button"
						variant="h6"
						onClick={handleOpenInNewTab}
					>
						{t("details.sections.general.title", {
							order: fields.assemblyOrder,
						})}
					</Link>
				</Tooltip>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.factoryOrder")}
					value={fields.factoryOrder}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.foDealerRegion")}
					value={fields.foDealerRegion}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.general.deliveryProposal")}
					value={fields.deliveryProposal}
					inputFormat="MMMM/yy"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.chassi")}
					value={fields.chassi}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.foDistrict")}
					value={fields.factoryOrderDistrict}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.productionPeriod")}
					value={fields.productionPeriod}
					type="number"
				/>
			</Grid>
			<Grid item xs={8}></Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.general.availabilityDate")}
					value={fields.availabilityDate}
					inputFormat="MMMM/yy"
				/>
			</Grid>
		</>
	);
};

export default observer(GeneralSection);
