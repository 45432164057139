import { configure } from "mobx";
import BasePreFilterDateStore from "../../../../PreFilter/Stores/BasePreFilterDateStore";
import { getDateRangeFilterObject } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterShippingDateStore extends BasePreFilterDateStore {
	getSpecificFilter(): any {
		return getDateRangeFilterObject(
			this.preFilter,
			"orderMCManagementDetail.shippingDate"
		);
	}
}

export default PreFilterShippingDateStore;
