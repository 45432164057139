import { action, configure } from "mobx";
import { FilterDescriptor } from "@progress/kendo-data-query";
import BasePreFilterChassiAOStore from "../../../../PreFilter/Stores/BasePreFilterChassiAOStore";
import { getFilterObjectArrayOfOrderKeyBySize } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterChassiAOStore extends BasePreFilterChassiAOStore {
	@action getFilterBySize = (value: string) => {
		return getFilterObjectArrayOfOrderKeyBySize(
			"chassi",
			"assemblyOrder",
			"factoryOrder",
			"",
			value
		);
	};

	@action set = (value: string) => this.setBase(value, this.getFilterBySize);

	@action getFilterDescriptor = (andFilters: Array<FilterDescriptor>) =>
		this.getFilterDescriptorBase(andFilters, this.getFilterBySize);

	@action getFilterDescriptorBySize = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>
	) =>
		this.getFilterDescriptorBySizeBase(
			andFilters,
			orFiltersGroups,
			this.getFilterBySize
		);
}

export default PreFilterChassiAOStore;
