import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../FormInputs/TextForm";
import { EngineeringCommentsSectionProps } from "../../interfaces/Sections/EngineeringCommentsSection";
import { propExistsInArray } from "../../../../../utils/ArrayUtils";

const EngineeringCommentsSection: React.FC<EngineeringCommentsSectionProps> = ({
	fieldsAvailableForEdition,
	setMCManagementFieldByKeyOnChange,
	fields,
}) => {
	const { t } = useTranslation("order");
	const editable = propExistsInArray(
		"engineeringComments",
		fieldsAvailableForEdition
	);
	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.engineeringComments")}
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.engineeringComments")}
					editable={editable}
					value={fields.engineeringComments}
					multiline
					maxRows={4}
					inputProps={{ maxLength: 500, style: { color: "red" } }}
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"engineeringComments",
							e.target.value
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(EngineeringCommentsSection);
