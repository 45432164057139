import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./HomePageGrid";
import { useStores } from "../stores/RootStore";
import PreFilters from "./PreFilters";
import HomePageCsvExport from "./HomePageCsvExport";
import {
	getClosedPeriodGridColumns,
	getClosedPeriodSortFieldsMap,
} from "../../../../utils/ArgGuidUtils";

const BaseIndex: React.FC = () => {
	const { t } = useTranslation(["order", "menu"]);
	const { menu, getVehicleType } = useStores().listStore;

	const { i18n } = useTranslation();
	const language = i18n.language ?? "en-US";

	const gridColumns = getClosedPeriodGridColumns(t, language, menu);
	const sortMap = getClosedPeriodSortFieldsMap(t, language, menu);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("closedPeriod.title", { ns: "menu" })} - ${t(getVehicleType(), { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item lg={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox breadcrumbs={breadcrumbs} />
					<HomePageCsvExport />
				</Box>
			</Grid>
			<PreFilters />
			<Grid item lg={12}>
				{gridColumns && (
					<HomePageGrid columns={gridColumns} sortMap={sortMap} />
				)}
			</Grid>
		</Grid>
	);
};

export default observer(BaseIndex);
