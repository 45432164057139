import { configure } from "mobx";
import BasePreFilterBooleanStore from "../../../../../../components/PreFilter/Stores/BasePreFilterBooleanStore";

configure({ enforceActions: "always" });

class PreFilterFirmBillingStore extends BasePreFilterBooleanStore {
	constructor(rootStore: any) {
		super(rootStore, "orderMCDelivery.firmBilling");
	}
}

export default PreFilterFirmBillingStore;
