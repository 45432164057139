import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./HomePageGrid";
import { useStores } from "../stores/RootStore";
import HomePageCsvExport from "./HomePageCsvExport";
import PreFilters from "./PreFilters/PreFilters";
import FollowUpArgBatchEditionModal from "./BatchEdition/FollowUpArgBatchEditionModal";
import SummaryTruck from "./Summary/SummaryTruck";
import SummaryBus from "./Summary/SummaryBus";
import {
	getFollowUpGridColumns,
	getFollowUpSortFieldsMap,
} from "../../../../utils/ArgGuidUtils";
import { GetBreadCrumbs } from "../utils/BreadCrumbs";

const BaseIndex: React.FC = () => {
	const { t } = useTranslation(["order", "menu"]);
	const { menu, getVehicleType } = useStores().listStore;
	const vehicleType = getVehicleType();
	const { i18n } = useTranslation();
	const language = i18n.language ?? "en-US";
	const columns = getFollowUpGridColumns(t, language, menu);
	const sortMap = getFollowUpSortFieldsMap(t, language, menu);

	const breadcrumbs = GetBreadCrumbs(t, vehicleType);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox breadcrumbs={breadcrumbs} />
					<HomePageCsvExport />
				</Box>
			</Grid>
			<FollowUpArgBatchEditionModal />
			<PreFilters />
			<Grid item xs={12}>
				{columns && <HomePageGrid columns={columns} sortMap={sortMap} />}
			</Grid>
			{vehicleType === "truck" ? <SummaryTruck /> : <SummaryBus />}
		</Grid>
	);
};

export default observer(BaseIndex);
