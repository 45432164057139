import { configure } from "mobx";
import BasePreFilterBooleanStore from "../../../../../../components/PreFilter/Stores/BasePreFilterBooleanStore";

configure({ enforceActions: "always" });

class PreFilterPhysicalDeliveryStore extends BasePreFilterBooleanStore {
	constructor(rootStore: any) {
		super(rootStore, "orderMCDelivery.physicalDelivery");
	}
}

export default PreFilterPhysicalDeliveryStore;
