import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { getBreadcrumbs } from "../utils/BreadcrumbsUtils";
import { useClosedPeriodArgStore } from "../stores/ClosedPeriodArgStore";
import { ownerGroupsOfClosedPeriodArg } from "../../../../constants/AuthGroups/closedPeriodArgPage";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import OpenOrderButtonAndModal from "./OpenOrderButtonAndModal";
import { getFieldsAvailableForEdition } from "../utils/EditableFieldsUtils";
import BaseDetailIndex from "../../../../components/Arg/DetailsBase/containers/Utils/BaseDetailIndex";
import { VehicleType } from "../../../../utils/GenericTypes";
interface ClosedPeriodDetailIndexProps {
	vehicleType: VehicleType;
}
const ClosedPeriodDetailIndex: React.FC<ClosedPeriodDetailIndexProps> = ({
	vehicleType,
}) => {
	const { t } = useTranslation("menu");
	const { isAuthorizedGroup } = useAuthStore();
	const userIsOwner = isAuthorizedGroup(ownerGroupsOfClosedPeriodArg);
	const fieldsAvailableForEdition = getFieldsAvailableForEdition(userIsOwner);
	const store = useClosedPeriodArgStore();
	const breadcrumbs = getBreadcrumbs(t);
	return (
		<BaseDetailIndex
			userIsOwner={userIsOwner}
			vehicleType={vehicleType}
			fieldsAvailableForEdition={fieldsAvailableForEdition}
			store={store}
		>
			<Box
				p={1}
				display="flex"
				justifyContent="flex-end"
				alignItems="flex-end"
				style={{ paddingRight: "0" }}
			>
				<TitleBox breadcrumbs={breadcrumbs} />
				{userIsOwner && <OpenOrderButtonAndModal />}
			</Box>
		</BaseDetailIndex>
	);
};

export default observer(ClosedPeriodDetailIndex);
