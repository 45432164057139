import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import { IPreFilterCommonOption } from "../Interfaces/IPreFilter";
import { getBooleanFilterObject } from "../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class BasePreFilterBooleanStore {
	@observable rootStore;
	@observable loading = false;
	@observable preFilter = "";
	@observable preFilterOptions: Array<IPreFilterCommonOption> = [];

	private field = "";

	constructor(rootStore: any, field: string) {
		this.rootStore = rootStore;
		this.field = field;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.loading = false;
		this.preFilter = "";
	};

	@action clean = () => {
		this.preFilter = "";
	};

	@action get = () => getBooleanFilterObject(this.field, this.preFilter);

	@action set = (value: string) => {
		this.preFilter = value;
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const filterObjects = this.get();

		if (filterObjects) {
			andFilters = andFilters.concat(filterObjects);
		}

		return andFilters;
	}

	@action setOptions = (options: Array<IPreFilterCommonOption>) =>
		(this.preFilterOptions = options);
}

export default BasePreFilterBooleanStore;
