import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../FormInputs/TextForm";
import { GeneralSectionBusProps } from "../../../interfaces/Sections/Bus/GeneralSectionBus";

const GeneralSectionBus: React.FC<GeneralSectionBusProps> = ({ fields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.general.busTitle", {
					order: fields.factoryOrder,
				})}
			</Grid>
			<Grid item xs={2}>
				<TextForm
					label={t("details.sections.general.foDealerRegion")}
					value={fields.foDealerRegion}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.chassi")}
					value={fields.chassi}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.foDistrict")}
					value={fields.factoryOrderDistrict}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextForm
					label={t("details.sections.general.productionPeriod")}
					value={fields.productionPeriod}
					type="number"
				/>
			</Grid>
		</>
	);
};

export default observer(GeneralSectionBus);
