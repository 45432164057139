import { State } from "@progress/kendo-data-query";
import { action, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import {
	ClosedPeriodMenuType,
	CLOSED_PERIOD_MENU_ARG_BUS,
	CLOSED_PERIOD_MENU_ARG_TRUCK,
} from "../../../../constants/Menus/ClosedPeriodMenuUtils";
import { ClosedPeriodProps } from "../interfaces/ClosedPeriodProps";
import { CultureType } from "../../../../utils/GenericTypes";
import { CsvOptionsProps } from "../../../Order/List/interfaces/CsvOptionsProps";
import {
	CSV_DISABLED_BUSES_CLOSED_PERIOD_ARG,
	CSV_DISABLED_TRUCKS_CLOSED_PERIOD_ARG,
} from "../../../../constants/csvButtonStorage";
import { getCsvButtonTimer } from "../../../../utils/CheckCsvTimer";
import { CsvQueueStatus } from "../../../../utils/enums/CsvQueueStatusEnum";

class ListStore {
	@observable menu: ClosedPeriodMenuType = CLOSED_PERIOD_MENU_ARG_TRUCK;
	@observable closedPeriod: Array<ClosedPeriodProps> = [];
	@observable loading: boolean = false;
	@observable lastDataState: State | undefined;
	@observable includeVariants: boolean = false;

	private api: CtadmvenApi;

	constructor(api: CtadmvenApi) {
		this.api = api;
		this.reset();
		makeObservable(this);
	}

	@action getVehicleType = () =>
		this.menu === CLOSED_PERIOD_MENU_ARG_TRUCK ? "truck" : "bus";

	@action reset = () => {
		this.menu = CLOSED_PERIOD_MENU_ARG_TRUCK;
		this.loading = false;
	};

	@action setMenu = (value: ClosedPeriodMenuType) => (this.menu = value);

	@action setLoading = (value: boolean) => (this.loading = value);

	@action setIsButtonCsvDisabledTruck = (value: Date) =>
		localStorage.setItem(
			CSV_DISABLED_TRUCKS_CLOSED_PERIOD_ARG,
			value.toString()
		);
	@action setIsButtonCsvDisabledBuses = (value: Date) =>
		localStorage.setItem(
			CSV_DISABLED_BUSES_CLOSED_PERIOD_ARG,
			value.toString()
		);

	@action setIncludeVariants = (value: boolean) =>
		(this.includeVariants = value);

	@action allRecords = async (dataState: State) => {
		this.setLastDataState(this.createDataStateObj(dataState));
		if (!this.lastDataState) return;

		this.setLoading(true);

		if (this.menu === CLOSED_PERIOD_MENU_ARG_TRUCK)
			return await this.api.closedPeriodService
				.listPaginated(this.lastDataState)
				.then((json) => Object.assign({ selected: false }, json.data))
				.finally(() => this.setLoading(false));

		if (this.menu === CLOSED_PERIOD_MENU_ARG_BUS)
			return await this.api.closedPeriodService
				.listBusPaginated(this.lastDataState)
				.then((json) => this.getFormattedData(json))
				.finally(() => this.setLoading(false));
	};

	getFormattedData = (json: any) => {
		json.data.data = json?.data?.data?.map((x: any) =>
			x.order.assemblyOrder.length > 3 &&
			x.order.assemblyOrder.startsWith("000")
				? {
						...x,
						order: {
							...x.order,
							assemblyOrder: x.order.assemblyOrder.slice(3),
						},
					}
				: x
		);

		return Object.assign({ selected: false }, json.data);
	};

	@action createDataStateObj = (dataState: State) =>
		({ ...dataState }) as State;

	@action private setLastDataState = (value: State) =>
		(this.lastDataState = value);

	@action csvExport = async (culture: CultureType): Promise<CsvQueueStatus> => {
		if (!this.lastDataState) {
			return CsvQueueStatus.AlreadyInQueue;
		}

		this.setLoading(true);

		const csvOptions: CsvOptionsProps = {
			includeVariants: this.includeVariants,
			culture: culture,
		};

		let status: CsvQueueStatus = CsvQueueStatus.Error;

		if (this.menu === CLOSED_PERIOD_MENU_ARG_TRUCK) {
			this.setIsButtonCsvDisabledTruck(getCsvButtonTimer());
			const result = await this.api.closedPeriodService.csvExport(
				this.lastDataState,
				csvOptions
			);
			status = result.data;
		} else if (this.menu === CLOSED_PERIOD_MENU_ARG_BUS) {
			this.setIsButtonCsvDisabledBuses(getCsvButtonTimer());
			const result = await this.api.closedPeriodService.csvExportBus(
				this.lastDataState,
				csvOptions
			);
			status = result.data;
		}

		this.setLoading(false);

		return status;
	};
}

export default ListStore;
