import { getStartDate } from "../../../../utils/DateUtils";
import {
	getDateOrDefault,
	getNumberOrDefault,
	getStringOrDefault,
} from "../../../../utils/ModelBuilderUItils";
import {
	FollowUpArgAccountingEditableProps,
	FollowUpArgArgMarketCompanyEditableProps,
	FollowUpArgBaseEditableProps,
	FollowUpArgBusinessEditableProps,
	FollowUpArgEditableProps,
	FollowUpArgEngineeringEditableProps,
	FollowUpArgFinancialEditableProps,
	FollowUpArgLogisticEditableProps,
} from "../interfaces/FollowUpArgEditable";

export const followUpArgBaseEditableModel: FollowUpArgBaseEditableProps = {
	orderId: "",
	generalComment: "",
};

export function followUpArgBaseEditableModelBuilder(data: any) {
	if (!data) {
		return followUpArgEditableModel;
	}
	const id = getStringOrDefault(data.id);
	return {
		orderId: id,
		generalComment: getStringOrDefault(data.generalComment),
	} as FollowUpArgBaseEditableProps;
}

const followUpArgArgMarketCompanyEditableModel: FollowUpArgArgMarketCompanyEditableProps =
	{
		...followUpArgBaseEditableModel,
		bodybuilder: "",
		salesman: "",
		finalCustomer: "",
		branchOffice: "",
		deliveryProbability: "",
		firmBilling: null,
		financialDelivery: null,
		physicalDelivery: null,
		finalDelivery: null,
		financialDeliveryUser: "",
		finalDeliveryUser: "",
		physicalDeliveryUser: "",
		firmBillingUser: "",
		dealerId: "",
		invoiceProposalDate: null,
		invoiceProposalNumber: "",
		comission: undefined,
		commercialCircular: "",
		usedDelivery: false,
		paymentForm: "",
		currency: "",
		finalValue: undefined,
		vehicleLocationId: "",
		salesSupportComments: "",
		financialComments: "",
		commercialComments: "",
		accountingComments: "",
		logisticComments: "",
		shippingDate: null,
		shippingNumber: "",
		certificateDate: null,
		billingForecast: null,
		shippingCompany: "",
		destination: "",
		paqueteId: "",
	};

export function followUpArgArgMarketCompanyEditableModelBuilder(data: any) {
	if (!data) {
		return followUpArgArgMarketCompanyEditableModel;
	}
	return {
		...followUpArgBaseEditableModelBuilder(data),
		bodybuilder: getStringOrDefault(data.bodybuilder),
		salesman: getStringOrDefault(data.salesman),
		finalCustomer: getStringOrDefault(data.finalCustomer),
		branchOffice: getStringOrDefault(data.branchOffice),
		dealerId: getStringOrDefault(data.orderMCManagement?.dealerId),
		invoiceProposalNumber: getStringOrDefault(
			data.orderMCManagement?.invoiceProposalNumber
		),
		invoiceProposalDate: getDateOrDefault(
			data.orderMCManagement?.invoiceProposalDate
		),
		commercialCircular: getStringOrDefault(
			data.orderMCManagement?.commercialCircular
		),
		usedDelivery: data.orderMCManagement?.usedDelivery,
		currency: getStringOrDefault(data.orderMCManagement?.currency),
		paymentForm: getStringOrDefault(data.orderMCManagement?.paymentForm),
		comission: getNumberOrDefault(data.orderMCManagement?.comission),
		finalValue: getNumberOrDefault(data.orderMCManagement?.finalValue),
		vehicleLocationId: getStringOrDefault(
			data.orderMCManagement?.vehicleLocationId
		),
		salesSupportComments: getStringOrDefault(
			data.orderMCManagement?.salesSupportComments
		),
		financialComments: getStringOrDefault(
			data.orderMCManagement?.financialComments
		),
		commercialComments: getStringOrDefault(
			data.orderMCManagement?.commercialComments
		),
		accountingComments: getStringOrDefault(
			data.orderMCManagement?.accountingComments
		),
		logisticComments: getStringOrDefault(
			data.orderMCManagement?.logisticComments
		),
		shippingDate: getDateOrDefault(data.orderMCManagement?.shippingDate),
		shippingNumber: getStringOrDefault(data.orderMCManagement?.shippingNumber),
		certificateDate: getDateOrDefault(data.orderMCManagement?.certificateDate),
		billingForecast: getDateOrDefault(data.orderMCManagement?.billingForecast),
		shippingCompany: getStringOrDefault(
			data.orderMCManagement?.shippingCompany
		),
		destination: getStringOrDefault(data.orderMCManagement?.destination),
		paqueteId: getStringOrDefault(data.orderMCManagement?.paqueteId),
		deliveryProbability: data.orderMCDelivery?.deliveryProbability ?? "None",
		firmBilling: getStartDate(data.orderMCDelivery?.firmBilling),
		financialDelivery: getStartDate(data.orderMCDelivery?.financialDelivery),
		physicalDelivery: getStartDate(data.orderMCDelivery?.physicalDelivery),
		finalDelivery: getStartDate(data.orderMCDelivery?.finalDelivery),
		financialDeliveryUser: getStringOrDefault(
			data.orderMCDelivery?.financialDeliveryUser
		),
		finalDeliveryUser: getStringOrDefault(
			data.orderMCDelivery?.finalDeliveryUser
		),
		firmBillingUser: getStringOrDefault(data.orderMCDelivery?.firmBillingUser),
		physicalDeliveryUser: getStringOrDefault(
			data.orderMCDelivery?.physicalDeliveryUser
		),
	} as FollowUpArgArgMarketCompanyEditableProps;
}

export const followUpArgEditableModel: FollowUpArgEditableProps = {
	...followUpArgArgMarketCompanyEditableModel,
	engineeringComments: "",
};

export function followUpArgEditableModelBuilder(data: any) {
	if (!data) {
		return followUpArgEditableModel;
	}
	return {
		...followUpArgArgMarketCompanyEditableModelBuilder(data),
		engineeringComments: getStringOrDefault(
			data.orderMCManagement?.engineeringComments
		),
	} as FollowUpArgEditableProps;
}

export const followUpArgFinancialEditableModel: FollowUpArgFinancialEditableProps =
	{
		...followUpArgBaseEditableModel,
		financialDelivery: null,
		financialDeliveryUser: "",
		financialComments: "",
	};

export function followUpArgFinancialEditableModelBuilder(
	data: any
): FollowUpArgFinancialEditableProps {
	if (!data) {
		return { ...followUpArgFinancialEditableModel };
	}
	return {
		...followUpArgBaseEditableModelBuilder(data),
		financialComments: getStringOrDefault(
			data.orderMCManagement?.financialComments
		),
		financialDelivery: getStartDate(data.orderMCDelivery?.financialDelivery),
		financialDeliveryUser: getStringOrDefault(
			data.orderMCDelivery?.financialDeliveryUser
		),
	};
}

export const followUpArgLogisticEditableModel: FollowUpArgLogisticEditableProps =
	{
		...followUpArgBaseEditableModel,
		logisticComments: "",
		billingForecast: null,
		shippingCompany: "",
		destination: "",
	};

export function followUpArgLogisticEditableModelBuilder(
	data: any
): FollowUpArgLogisticEditableProps {
	if (!data) {
		return { ...followUpArgEditableModel };
	}
	return {
		...followUpArgBaseEditableModelBuilder(data),
		logisticComments: getStringOrDefault(
			data.orderMCManagement?.logisticComments
		),
		billingForecast: getDateOrDefault(data.orderMCManagement?.billingForecast),
		shippingCompany: getStringOrDefault(
			data.orderMCManagement?.shippingCompany
		),
		destination: getStringOrDefault(data.orderMCManagement?.destination),
	};
}

export const followUpArgAccountingEditableModel: FollowUpArgAccountingEditableProps =
	{
		...followUpArgBaseEditableModel,
		accountingComments: "",
	};

export function followUpArgAccountingEditableModelBuilder(
	data: any
): FollowUpArgAccountingEditableProps {
	if (!data) {
		return { ...followUpArgAccountingEditableModel };
	}
	return {
		...followUpArgBaseEditableModelBuilder(data),
		accountingComments: getStringOrDefault(
			data.orderMCManagement?.accountingComments
		),
	};
}

export const followUpArgBusinessEditableModel: FollowUpArgBusinessEditableProps =
	{
		...followUpArgBaseEditableModel,
		commercialComments: "",
	};

export function followUpArgBusinessEditableModelBuilder(
	data: any
): FollowUpArgBusinessEditableProps {
	if (!data) {
		return { ...followUpArgBusinessEditableModel };
	}
	return {
		...followUpArgBaseEditableModelBuilder(data),
		commercialComments: getStringOrDefault(
			data.orderMCManagement?.commercialComments
		),
	};
}

export const followUpArgEngineeringEditableModel: FollowUpArgEngineeringEditableProps =
	{
		...followUpArgBaseEditableModel,
		engineeringComments: "",
		paqueteId: "",
	};

export function followUpArgEngineeringEditableModelBuilder(
	data: any
): FollowUpArgEngineeringEditableProps {
	if (!data) {
		return { ...followUpArgEngineeringEditableModel };
	}
	return {
		...followUpArgBaseEditableModelBuilder(data),
		engineeringComments: getStringOrDefault(
			data.orderMCManagement?.engineeringComments
		),
		paqueteId: getStringOrDefault(data.orderMCManagement?.paqueteId),
	};
}
