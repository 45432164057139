import {
	getDateOrDefault,
	getNumberOrDefault,
	getNumberOrDefaultZero,
	getStringOrDefault,
} from "../../../../utils/ModelBuilderUItils";
import { BaseFieldsProps } from "../interfaces/BaseFieldsProps";
import { orderMCDeliveryFieldsModel } from "./OrderMCDeliveryFieldsModel";
import { orderMCManagementFieldsModel } from "./OrderMCManagementFieldsModel";

export const baseReadOnlyModel: BaseFieldsProps = {
	id: "",
	factoryOrder: "",
	foDealerRegion: "",
	chassi: "",
	factoryOrderDistrict: "",
	foOriginalCustomerOM: "",
	finalCustomer: "",
	bodybuilder: "",
	salesman: "",
	branchOffice: "",
	generalComment: "",
	productionModel: "",
	lineCode: "",
	commercialModel: "",
	class: "",
	vehicleIdentificationNumber: "",
	engineSerialNumber: "",
	lastUpdateEventTypeName: "",
	lcd: null,
	cdd: null,
	cddeol: null,
	online: null,
	offline: null,
	asWeekDate: null,
	asWeekDateInitial: null,
	onlineDelay: 0,
	asWeekDelay: 0,
	greenOk: null,
	lineSequenceDate: null,
	lineSequence: "",
	variants: [],
	orderMCDelivery: orderMCDeliveryFieldsModel,
	orderMCManagement: orderMCManagementFieldsModel,
};

export function baseReadOnlyModelBuilder(data: BaseFieldsProps) {
	if (!data) {
		return baseReadOnlyModel;
	}
	return {
		...baseReadOnlyModel,
		id: getStringOrDefault(data.id),
		factoryOrder: getStringOrDefault(data.factoryOrder),
		foDealerRegion: getStringOrDefault(data.foDealerRegion),
		chassi: getStringOrDefault(data.chassi),
		factoryOrderDistrict: getStringOrDefault(data.factoryOrderDistrict),
		productionPeriod: getNumberOrDefault(data.productionPeriod),
		foOriginalCustomerOM: getStringOrDefault(data.foOriginalCustomerOM),
		finalCustomer: getStringOrDefault(data.finalCustomer),
		bodybuilder: getStringOrDefault(data.bodybuilder),
		salesman: getStringOrDefault(data.salesman),
		branchOffice: getStringOrDefault(data.branchOffice),
		generalComment: getStringOrDefault(data.generalComment),
		productionModel: getStringOrDefault(data.productionModel),
		factoryYear: getNumberOrDefault(data.factoryYear),
		lineCode: getStringOrDefault(data.lineCode),
		commercialModel: getStringOrDefault(data.commercialModel),
		modelYear: getNumberOrDefault(data.modelYear),
		class: getStringOrDefault(data.class),
		vehicleIdentificationNumber: getStringOrDefault(
			data.vehicleIdentificationNumber
		),
		engineSerialNumber: getStringOrDefault(data.engineSerialNumber),
		lastUpdateEventTypeName: getStringOrDefault(data.lastUpdateEventTypeName),
		lastUpdateDate: getDateOrDefault(data.lastUpdateDate),
		lcd: getDateOrDefault(data.lcd),
		cdd: getDateOrDefault(data.cdd),
		cddeol: getDateOrDefault(data.cddeol),
		online: getDateOrDefault(data.online),
		offline: getDateOrDefault(data.offline),
		asWeekDate: getDateOrDefault(data.asWeekDate),
		asWeekDateInitial: getDateOrDefault(data.asWeekDateInitial),
		onlineDelay: getNumberOrDefaultZero(data.onlineDelay),
		asWeekDelay: getNumberOrDefaultZero(data.asWeekDelay),
		greenOk: getDateOrDefault(data.greenOk),
		lineSequenceDate: getDateOrDefault(data.lineSequenceDate),
		lineSequence: getStringOrDefault(data.lineSequence),
	} as BaseFieldsProps;
}
