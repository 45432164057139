import { configure } from "mobx";
import BasePreFilterStringStore from "../../../../../../components/PreFilter/Stores/BasePreFilterStringStore";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCustomerStoreOM extends BasePreFilterStringStore {
	filterKey = "foOriginalCustomerOM";
	filterSize = 100;
	operator = "contains";

	getSpecificFilter(): any {
		return getFilterObjectArrayOfStringKeyBySize(
			this.filterSize,
			this.preFilter,
			this.filterKey,
			this.operator
		);
	}
}

export default PreFilterCustomerStoreOM;
