import { OrderMCDeliveryFieldsProps } from "../interfaces/OrderMCDeliveryFieldsProps";
import { getStartDate } from "../../../../utils/DateUtils";
import { getStringOrDefault } from "../../../../utils/ModelBuilderUItils";

export const orderMCDeliveryFieldsModel: OrderMCDeliveryFieldsProps = {
	deliveryProbability: "None",
	firmBilling: null,
	financialDelivery: null,
	physicalDelivery: null,
	finalDelivery: null,
	financialDeliveryUser: "",
	finalDeliveryUser: "",
	firmBillingChecked: false,
	financialDeliveryChecked: false,
	physicalDeliveryChecked: false,
	finalDeliveryChecked: false,
	firmBillingUser: "",
	physicalDeliveryUser: "",
};

export function orderMCDeliveryFieldsModelBuilder(
	data: any
): OrderMCDeliveryFieldsProps {
	if (!data) {
		return orderMCDeliveryFieldsModel;
	}
	return {
		...orderMCDeliveryFieldsModel,
		deliveryProbability: data.deliveryProbability ?? "None",
		firmBilling: getStartDate(data.firmBilling),
		financialDelivery: getStartDate(data.financialDelivery),
		physicalDelivery: getStartDate(data.physicalDelivery),
		finalDelivery: getStartDate(data.finalDelivery),
		financialDeliveryUser: getStringOrDefault(data.financialDeliveryUser),
		finalDeliveryUser: getStringOrDefault(data.finalDeliveryUser),
		firmBillingChecked: Boolean(data.firmBilling),
		financialDeliveryChecked: Boolean(data.financialDelivery),
		physicalDeliveryChecked: Boolean(data.physicalDelivery),
		finalDeliveryChecked: Boolean(data.finalDelivery),
		firmBillingUser: getStringOrDefault(data.firmBillingUser),
		physicalDeliveryUser: getStringOrDefault(data.physicalDeliveryUser),
	};
}
