import { configure } from "mobx";
import BasePreFilterBooleanStore from "../../../../../../components/PreFilter/Stores/BasePreFilterBooleanStore";

configure({ enforceActions: "always" });

class PreFilterFinancialDeliveryStore extends BasePreFilterBooleanStore {
	constructor(rootStore: any) {
		super(rootStore, "orderMCDelivery.financialDelivery");
	}
}

export default PreFilterFinancialDeliveryStore;
