/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { LoadingPage } from "../../../../LoadingPage/LoadingPage";
import NoContentResult from "../../../../msal/NoContentResult";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { useParams } from "react-router-dom";
import { VehicleType } from "../../../../../utils/GenericTypes";
import { ArgDetailsBaseStore } from "../../stores/ArgDetailsBaseStore";
import { toast } from "react-toastify";
import { Box, Grid } from "@mui/material";
import SaveBar from "../../../../SaveBar/SaveBar";
import DetailsTimeline from "./DetailsTimeline";
import GeneralAccordion from "../Accordions/GeneralAccordion";
import CommercialAccordion from "../Accordions/CommercialAccordion";
import VehicleAccordion from "../Accordions/VehicleAccordion";
import AvailabilityInvoicingDeliveryAccordion from "../Accordions/AvailabilityInvoicingDeliveryAccordion";

interface BaseDetailIndexProps {
	userIsOwner: boolean;
	vehicleType: VehicleType;
	fieldsAvailableForEdition: string[];
	store: ArgDetailsBaseStore;
	children: React.ReactNode;
}

const BaseDetailIndex: React.FC<BaseDetailIndexProps> = ({
	userIsOwner,
	vehicleType,
	fieldsAvailableForEdition,
	store,
	children,
}) => {
	const { t } = useTranslation("general");
	const { id } = useParams<{ id: string }>();
	const { i18n } = useTranslation();
	const language = i18n.language ?? "en-US";
	const { graphData } = useAuthStore();
	const [loading, setLoading] = React.useState(true);

	const {
		fields,
		loadAllDetails,
		update,
		hasChanges,
		loadAllOptions,
		loadVariants,
		setFieldByKeyOnChange,
		setMCManagementFieldByKeyOnChange,
		setMCDeliveryFieldByKeyOnChange,
		dealersOptions,
		paqueteOptions,
		vehicleLocationOptions,
		paymentTermsOptions,
		shippingCompaniesOptions,
	} = store;

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		await loadAllDetails(id ?? "", graphData?.mail ?? "", vehicleType).finally(
			() => setLoading(false)
		);
	}, [id]);

	const fetchOptions = React.useCallback(async () => {
		setLoading(true);
		await loadAllOptions(fieldsAvailableForEdition, language).finally(() =>
			setLoading(false)
		);
	}, [loadAllOptions]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);
	React.useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);
	const handleSubmit = async () => {
		if (!hasChanges) {
			toast.info(t("noChangesToSave", { ns: "general" }).toString());
		} else {
			setLoading(true);
			await update()
				.then(() =>
					toast.success(
						t("editRecordWithSuccess", { ns: "general" }).toString()
					)
				)
				.catch((err: any) => toast.error(err.message))
				.finally(() => fetchDetails());
		}
	};
	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && fields.id && (
				<>
					{" "}
					{children}
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs mb={20}>
								<Grid container spacing={2}>
									<GeneralAccordion
										lastUpdateDetails={{ ...fields }}
										generalDetails={{
											fields: { ...fields },
										}}
										vehicleType={vehicleType}
									/>
									<CommercialAccordion
										customerDetails={{
											fieldsAvailableForEdition,
											fields: { ...fields, ...fields.orderMCManagement },
											setFieldByKeyOnChange,
											setMCManagementFieldByKeyOnChange,
											dealersOptions,
										}}
										mktCompanyCommercial={{
											fieldsAvailableForEdition,
											fields: { ...fields.orderMCManagement },
											setMCManagementFieldByKeyOnChange,
											vehicleLocationOptions,
											paymentTermsOptions,
										}}
										mktCompanyAdditionalComments={{
											fieldsAvailableForEdition,
											fields: { ...fields.orderMCManagement },
											setMCManagementFieldByKeyOnChange,
										}}
										mktCompanyDeliverySection={{
											fieldsAvailableForEdition,
											fields: {
												...fields.orderMCManagement,
												...fields.orderMCDelivery,
											},
											userIsOwner,
											setMCDeliveryFieldByKeyOnChange,
											setMCManagementFieldByKeyOnChange,
										}}
										generalComment={{
											fieldsAvailableForEdition,
											fields: { ...fields },
											setFieldByKeyOnChange,
										}}
										paymentTermsOptions={paymentTermsOptions}
									/>
									<VehicleAccordion
										modelDetails={{
											fieldsAvailableForEdition,
											fields: {
												...fields,
												...fields.orderMCManagement,
											},
											setMCManagementFieldByKeyOnChange,
											paqueteOptions,
										}}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...fields },
										}}
										variantsDetails={{
											readOnlyFields: { variants: fields.variants },
											loadVariants: loadVariants,
										}}
										engineeringCommentsDetails={{
											fieldsAvailableForEdition,
											fields: { ...fields.orderMCManagement },
											setMCManagementFieldByKeyOnChange,
										}}
										vehicleType={vehicleType}
									/>
									<AvailabilityInvoicingDeliveryAccordion
										forecastBillingDetails={{
											fieldsAvailableForEdition,
											fields: {
												...fields.orderMCManagement,
											},
											setMCManagementFieldByKeyOnChange,
											shippingCompaniesOptions,
										}}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasChanges}
								/>
							</Grid>
							<DetailsTimeline />
						</Grid>
					</Box>
				</>
			)}
			{!loading && !fields.id && <NoContentResult />}
		</>
	);
};

export default observer(BaseDetailIndex);
