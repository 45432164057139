import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import SwitchForm from "../../../../FormInputs/SwitchForm";
import TextForm from "../../../../FormInputs/TextForm";
import DateForm from "../../../../FormInputs/DateForm";
import SelectForm from "../../../../FormInputs/SelectForm";
import { getCurrencyOptions } from "../../../../../utils/SelectOptions";
import { maxValueValidator } from "../../../../../utils/FormValidators";
import AutoCompleteForm from "../../../../FormInputs/AutoCompleteForm";
import { MktCompanyCommercialSectionProps } from "../../interfaces/Sections/MktCompanyCommercialSection";
import { propExistsInArray } from "../../../../../utils/ArrayUtils";

const MktCompanyCommercialSection: React.FC<
	MktCompanyCommercialSectionProps
> = ({
	fieldsAvailableForEdition,
	fields,
	setMCManagementFieldByKeyOnChange,
	vehicleLocationOptions,
	paymentTermsOptions,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const currencyOptions = getCurrencyOptions();

	const formValidators = (value: string, key?: string) => {
		if (key === "comission" && !maxValueValidator(parseFloat(value), 15))
			return t("validator.maxNumber", { max: 15, ns: "general" });
		return "";
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyCommercial.title")}
			</Grid>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.mktCompanyCommercial.invoiceProposalDate")}
					value={fields.invoiceProposalDate}
					editable={propExistsInArray(
						"invoiceProposalDate",
						fieldsAvailableForEdition
					)}
					onChange={(dataItem: any) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("invoiceProposalDate", dataItem)
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					label={t(
						"details.sections.mktCompanyCommercial.invoiceProposalNumber"
					)}
					value={fields.invoiceProposalNumber}
					editable={propExistsInArray(
						"invoiceProposalNumber",
						fieldsAvailableForEdition
					)}
					inputProps={{ maxLength: 12 }}
					name="invoiceProposalNumber"
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"invoiceProposalNumber",
							e.target.value
						)
					}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.comission")}
					value={fields.comission}
					editable={propExistsInArray("comission", fieldsAvailableForEdition)}
					name="comission"
					validator={formValidators}
					onChange={(
						e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
						hasErrors?: boolean
					) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"comission",
							parseFloat(parseFloat(e.target.value).toFixed(2)),
							hasErrors
						)
					}
					inputProps={{
						step: 2,
					}}
					type="number"
				/>
			</Grid>
			<Grid item xs={3}>
				<SwitchForm
					editable={propExistsInArray(
						"usedDelivery",
						fieldsAvailableForEdition
					)}
					label={t("details.sections.mktCompanyCommercial.usedDelivery")}
					checked={fields.usedDelivery}
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("usedDelivery", e.target.checked)
					}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.commercialCircular")}
					value={fields.commercialCircular}
					editable={propExistsInArray(
						"commercialCircular",
						fieldsAvailableForEdition
					)}
					name="commercialCircular"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"commercialCircular",
							e.target.value
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.paymentForm")}
					labelId={"sections.mktCompanyCommercial-paymentForm-label"}
					value={fields.paymentForm}
					options={paymentTermsOptions}
					editable={propExistsInArray("paymentForm", fieldsAvailableForEdition)}
					hasEmptyDefaultItem
					hasEditIcon
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"paymentForm",
							e.target.value as string
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.mktCompanyCommercial.currency")}
					labelId={"sections.mktCompanyCommercial-currency-label"}
					value={fields.currency}
					options={currencyOptions}
					editable={propExistsInArray("currency", fieldsAvailableForEdition)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"currency",
							e.target.value as string
						)
					}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.mktCompanyCommercial.finalValue")}
					value={fields.finalValue}
					editable={propExistsInArray("finalValue", fieldsAvailableForEdition)}
					name="finalValue"
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"finalValue",
							parseFloat(parseFloat(e.target.value).toFixed(2))
						)
					}
					inputProps={{
						step: 2,
					}}
					type="number"
				/>
			</Grid>
			<Grid item xs={12}>
				<AutoCompleteForm
					label={t("details.sections.mktCompanyCommercial.vehicleLocation")}
					labelId={"sections.mktCompanyCommercial-vehicleLocation-label"}
					value={fields.vehicleLocationId}
					options={vehicleLocationOptions}
					editable={propExistsInArray(
						"vehicleLocationId",
						fieldsAvailableForEdition
					)}
					hasEmptyDefaultItem
					hasEditIcon
					onChange={(value) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange("vehicleLocationId", value)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(MktCompanyCommercialSection);
