import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../FormInputs/TextForm";
import SelectForm from "../../../../FormInputs/SelectForm";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import { propExistsInArray } from "../../../../../utils/ArrayUtils";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	fieldsAvailableForEdition,
	fields,
	setFieldByKeyOnChange,
	setMCManagementFieldByKeyOnChange,
	dealersOptions,
}) => {
	const { t } = useTranslation(["order", "general"]);
	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.originalCustomer")}
					value={fields.foOriginalCustomerOM}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					value={fields.finalCustomer}
					editable={propExistsInArray(
						"finalCustomer",
						fieldsAvailableForEdition
					)}
					name="FinalCustomer"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setFieldByKeyOnChange &&
						setFieldByKeyOnChange("finalCustomer", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.bodybuilder")}
					value={fields.bodybuilder}
					editable={propExistsInArray("bodybuilder", fieldsAvailableForEdition)}
					name="Bodybuilder"
					inputProps={{ maxLength: 50 }}
					onChange={(e) =>
						setFieldByKeyOnChange &&
						setFieldByKeyOnChange("bodybuilder", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.salesman")}
					value={fields.salesman}
					editable={propExistsInArray("salesman", fieldsAvailableForEdition)}
					name="Salesman"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setFieldByKeyOnChange &&
						setFieldByKeyOnChange("salesman", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.branchoffice")}
					value={fields.branchOffice}
					editable={propExistsInArray(
						"branchOffice",
						fieldsAvailableForEdition
					)}
					name="BranchOffice"
					inputProps={{ maxLength: 50 }}
					onChange={(e) =>
						setFieldByKeyOnChange &&
						setFieldByKeyOnChange("branchOffice", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.customer.dealer")}
					labelId={"sections.customer-dealer-label"}
					value={fields.dealerId}
					options={dealersOptions}
					editable={propExistsInArray("dealerId", fieldsAvailableForEdition)}
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setMCManagementFieldByKeyOnChange &&
						setMCManagementFieldByKeyOnChange(
							"dealerId",
							e.target.value as string
						)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(CustomerSection);
