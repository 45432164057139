import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { BaseModelSectionProps } from "../../interfaces/Sections/BaseModelSection";
import TextForm from "../../../../FormInputs/TextForm";

const BaseModelSection: React.FC<BaseModelSectionProps> = ({ fields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.model.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.productionModel")}
					value={fields.productionModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.factoryYear")}
					value={fields.factoryYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.lineCode")}
					value={fields.lineCode}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialModel")}
					value={fields.commercialModel}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.modelYear")}
					value={fields.modelYear}
					type="number"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.class")}
					value={fields.class}
				/>
			</Grid>
		</>
	);
};

export default observer(BaseModelSection);
