import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import { DateRangeFormProps } from "../../../../../utils/GenericInterfaces";
import { getDateRangeFilterObject } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterPrevisionBillingMonthStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: DateRangeFormProps | undefined;

	constructor(rootStore: any) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.loading = false;
		this.preFilter = undefined;
	};

	@action clean = () => (this.preFilter = undefined);

	@action get = () =>
		getDateRangeFilterObject(
			this.preFilter,
			"orderMCManagementDetail.billingForecast"
		);

	@action set = (monthAvailabilityFilter: DateRangeFormProps | undefined) =>
		(this.preFilter = monthAvailabilityFilter);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const previsionBillingMonthFilters = this.get();

		if (previsionBillingMonthFilters)
			andFilters = andFilters.concat(previsionBillingMonthFilters);

		return andFilters;
	}

	@action hasPreFilter = () => this.preFilter !== undefined;
}

export default PreFilterPrevisionBillingMonthStore;
