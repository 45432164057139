export interface OrderMCDeliveryFieldsProps {
	deliveryProbability: string;
	firmBilling: Date | null;
	firmBillingChecked: boolean;
	financialDelivery: Date | null;
	financialDeliveryChecked: boolean;
	physicalDelivery: Date | null;
	physicalDeliveryChecked: boolean;
	finalDelivery: Date | null;
	finalDeliveryChecked: boolean;
	financialDeliveryUser: string;
	finalDeliveryUser: string;
	physicalDeliveryUser: string;
	firmBillingUser: string;
}

const CURRENT_DATE_USER_FIELDS = [
	"financialDelivery",
	"finalDelivery",
	"firmBilling",
	"physicalDelivery",
] as const;
type OrderMCDeliveryCurrentDateUserTypeTuple = typeof CURRENT_DATE_USER_FIELDS;

export type OrderMCDeliveryCurrentDateUserType =
	OrderMCDeliveryCurrentDateUserTypeTuple[number];

export function isOrderMCDeliveryCurrentDateUserType(
	value: string
): value is OrderMCDeliveryCurrentDateUserType {
	return CURRENT_DATE_USER_FIELDS.includes(
		value as OrderMCDeliveryCurrentDateUserType
	);
}
