import { configure } from "mobx";
import BasePreFilterStringStore from "../../../../PreFilter/Stores/BasePreFilterStringStore";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterBusinessControlVehicleStore extends BasePreFilterStringStore {
	filterKey = "businessControlVehicle";
	filterSize = 51;
	operator = "contains";

	getSpecificFilter(): any {
		return getFilterObjectArrayOfStringKeyBySize(
			this.filterSize,
			this.preFilter,
			this.filterKey,
			this.operator
		);
	}
}

export default PreFilterBusinessControlVehicleStore;
