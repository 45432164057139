import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../stores/RootStore";
import { FOLLOWUP_MENU_ARG_BUS } from "../../../../constants/Menus/FollowUpMenuUtils";
import BaseIndex from "./BaseIndex";

const BusIndex: React.FC = () => {
	const { setMenu, menu, reset } = useStores().listStore;
	const fetch = React.useCallback(async () => {
		reset();
		setMenu(FOLLOWUP_MENU_ARG_BUS);
	}, [reset, setMenu]);
	React.useEffect(() => {
		fetch();
	}, [fetch]);
	return <>{menu === FOLLOWUP_MENU_ARG_BUS && <BaseIndex />}</>;
};

export default observer(BusIndex);
