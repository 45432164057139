import { configure } from "mobx";
import BasePreFilterDateStore from "../../../../PreFilter/Stores/BasePreFilterDateStore";
import { getDateRangeFilterObject } from "../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterAsWeekDateStore extends BasePreFilterDateStore {
	getSpecificFilter(): any {
		return getDateRangeFilterObject(this.preFilter, "asWeekDate");
	}
}

export default PreFilterAsWeekDateStore;
